.grecaptcha-badge {
    visibility: hidden;
}

html,
body,
#root {
  background-color: #000220;
  background-size: cover;
  background-position: center;
  -webkit-overflow-scrolling: touch;
  //-webkit-overflow-scrolling: auto;
}

@supports(padding:max(0px)) {
  body, header, footer {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
      padding-top: min(0vmin, constant(safe-area-inset-top));
      padding-bottom: min(0vmin, constant(safe-area-inset-bottom));
  }
}


// Cookie consent variables
#cc-main {
    --cc-font-family: 'Manrope', sans-serif;

    --cc-bg: #000220;
    --cc-primary-color: #ffffff;
    --cc-secondary-color: #9699A6;

    --cc-btn-primary-bg: #ffffff;
    --cc-btn-primary-color: #000220;
    --cc-btn-primary-hover-bg: #CDFD50;
    --cc-btn-primary-hover-color: #000220;

    --cc-btn-secondary-bg: #000220;
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-border-color: #ffffff;
    --cc-btn-secondary-hover-bg: #ffffff;
    --cc-btn-secondary-hover-color: #000220;
    --cc-btn-secondary-hover-border-color: #ffffff;

    --cc-cookie-category-block-bg: var(--cc-bg);
    --cc-cookie-category-block-border: #ffffff;
    --cc-cookie-category-block-hover-bg: #CDFD50;
    --cc-cookie-category-block-hover-border: #CDFD50;
    --cc-cookie-category-expanded-block-hover-bg: #CDFD50;
    --cc-cookie-category-expanded-block-bg: var(--cc-bg);
    --cc-toggle-readonly-bg: var(--cc-secondary-color);
    --cc-toggle: red;
    --cc-overlay-bg: rgba(0, 0, 0, 0.5)!important;

    --cc-toggle-on-knob-bg: #CDFD50;
    --cc-toggle-readonly-knob-bg: var( --cc-cookie-category-block-bg);

    --cc-separator-border-color: var(--cc-secondary-color);

    --cc-footer-border-color: var(--cc-secondary-color);
    --cc-footer-bg: var(--cc-bg);
}